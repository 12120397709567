<template>
  <div class="add-driver">
    <el-form
      :model="addForm"
      :rules="rules"
      :inline="true"
      ref="addForm"
      label-width="110px"
      label-position="left"
      class="demo-form-inline dialog-form dialog-form-inline"
    >
      <el-form-item label="驾驶员姓名：" prop="name">
        <el-input
          v-model.trim="addForm.name"
          placeholder="请输入姓名"
        ></el-input>
      </el-form-item>

      <el-form-item label="所属企业：" prop="companyId">
        <companySelect
          ref="companySelect"
          :clearable="true"
          :companyTree="companyList"
          :valueName="valueNameAdd"
          :value="valueAdd"
          @getValue="getAddGroupId"
        ></companySelect>
      </el-form-item>

      <el-form-item label="工号：" prop="workNum">
        <el-input
          v-model.trim="addForm.workNum"
          placeholder="请输入工号"
        ></el-input>
      </el-form-item>

      <el-form-item label="性别：" prop="sex">
        <el-select clearable v-model.trim="addForm.sex">
          <el-option
            v-for="item in sexList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="身份证号码：" prop="idCard">
        <el-input
          v-model.trim="addForm.idCard"
          :disabled="isIDCardEdit"
          placeholder="请输入身份证号码"
        ></el-input>
      </el-form-item>

      <el-form-item label="联系电话：" prop="phone">
        <el-input
          v-model.trim="addForm.phone"
          placeholder="请输入联系电话"
        ></el-input>
      </el-form-item>

      <el-form-item label="星级：" prop="starLevel">
        <el-select clearable v-model.trim="addForm.starLevel">
          <el-option
            v-for="item in starLevelList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="卡号：" prop="cardNo">
        <el-input
          v-model.trim="addForm.cardNo"
          placeholder="请输入卡号"
        ></el-input>
      </el-form-item>
    </el-form>
    <p class="from-title form-title3">驾驶员照片</p>
    <div class="form-area photo-area">
      <el-upload
        :action="uploadUrl()"
        :headers="getHeader()"
        accept="image/png, image/gif, image/jpg, image/jpeg"
        list-type="picture-card"
        :limit="3"
        :file-list="addForm.photos"
        :before-remove="handleBeforeRemove"
        :on-success="handleAvatarSuccess"
        :class="{ disUoloadSty: isPhotoExceed }"
        ref="upload"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
    </div>

    <p class="form-title form-title3">
      <span>从业资格证信息</span>
    </p>
    <el-form
      :model="addForm2"
      :rules="rules"
      :inline="true"
      ref="addForm2"
      label-width="110px"
      label-position="left"
      class="demo-form-inline dialog-form dialog-form-inline"
    >
      <el-form-item label="资格证号：" prop="credentialsNo">
        <el-input
          :disabled="true"
          v-model.trim="addForm2.credentialsNo"
          placeholder="请输入从业资格证号"
        ></el-input>
      </el-form-item>
      <el-form-item label="到期时间：" prop="credentialsEndDate">
        <el-date-picker
          v-model="addForm2.credentialsEndDate"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="选择日期"
          :class="
            addForm2.credentialsEndWarning && addForm2.credentialsEndWarning
              ? 'expired'
              : ''
          "
        ></el-date-picker>
      </el-form-item>
    </el-form>

    <p class="form-title form-title3">
      <span>驾驶证信息</span>
    </p>
    <el-form
      :model="addForm3"
      :rules="rules"
      :inline="true"
      ref="addForm3"
      label-width="110px"
      label-position="left"
      class="demo-form-inline dialog-form dialog-form-inline"
    >
      <el-form-item label="驾驶证号码：" prop="drivingLicense">
        <el-input
          v-model.trim="addForm3.drivingLicense"
          :disabled="true"
          placeholder="请输入驾驶证号码"
        ></el-input>
      </el-form-item>
      <el-form-item label="到期时间：" prop="drivingEndDate">
        <div class="flex">
          <el-date-picker
          v-model="addForm3.drivingEndDate"
          :disabled="isLongDate"
          value-format="yyyy-MM-dd"
          type="date"
          :placeholder="isLongDate?'长期':'选择日期'"
          :class="
            addForm3.drivingEndWarning && addForm3.drivingEndWarning
              ? 'expired'
              : ''
          "
        ></el-date-picker>
        <el-checkbox v-model="isLongDate" class="check">长期</el-checkbox>
        </div>
      </el-form-item>
      <el-form-item label="驾驶证类型：" prop="drivingType">
        <el-select clearable v-model.trim="addForm3.drivingType">
          <el-option
            v-for="item in drivingTypeList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <p class="form-title form-title3">
      <span>驾驶车辆</span>
    </p>
    <el-form
      :model="addForm4"
      :rules="rules"
      :inline="true"
      ref="addForm4"
      label-width="110px"
      label-position="left"
      class="demo-form-inline dialog-form dialog-form-inline"
    >
      <el-form-item
        label="类型："
        :prop="addForm4.vehicleNos.length > 0 ? 'driverType' : ''"
      >
        <el-select
          clearable
          placeholder="请选择类型"
          v-model="addForm4.driverType"
        >
          <el-option
            v-for="item in drivingChoiceList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="选择车辆："
        :prop="addForm4.driverType === '0' ? 'vehicleNos' : ''"
      >
        <driverCarSearch
          ref="driverCarSearch"
          @getData="getVehIds"
          :driverType="addForm4.driverType"
          :companyId="addForm.companyId"
          :choosedCph="choosedCph"
        ></driverCarSearch>
      </el-form-item>
    </el-form>
    <p class="form-title form-title3 form-title4">
      备注：固定驾驶某辆车的驾驶员，选择主驾，只绑定一辆车；机动驾驶员，可不设置或者设置多辆驾驶车辆
    </p>
  </div>
</template>

<script>
import {
  checkName,
  checkJobNumber,
  checkIdentificationNumber,
  checkPhone,
  checkQualificationCertificateNumber,
  checkCardNo,
  checkDriverLicenseNumber,
} from "@/common/utils/index.js";
import {
  getDriverDetail,
  insertDriver,
  updateDriver,
} from "@/api/lib/api.js";
import config from "@/api/static/config";
import driverCarSearch from "./driverCarSearch.vue";
import companySelect from "@/components/treeSelect/companySelect.vue";
export default {
  name: 'addDriver',
  components: {
    driverCarSearch,
    companySelect
  },
  props: {
    companyList: {
      type: Array,
      default: () => []
    },
    sexList: {
      type: Array,
      default: () => []
    },
    isIDCardEdit: {
      type: Boolean,
      default: true
    },
    starLevelList: {
      type: Array,
      default: () => []
    },
    drivingTypeList: {
      type: Array,
      default: () => []
    },
    drivingChoiceList: {
      type: Array,
      default: () => []
    },
    rowData:{
      type:Object,
      default:()=>{}
    },
    title:{
      type:String,
      default:""
    }
  },
  data () {
    //form校验
    const mergeValue = (rule,value,callback)=>{
      if (value || this.isLongDate) {
        callback();
      } else {
        callback(new Error("请选择到期时间"));
      }
    };
    return {
      addForm: {
        name: "",
        companyId: "",
        workNum: "",
        sex: "",
        idCard: "",
        status: "0",
        phone: "",
        starLevel: "",
        photos: [],
        cardNo: ""
      },
      addForm2: {
        credentialsNo: "",
        credentialsEndDate: "",
        credentialsEndWarning: false
      },
      addForm3: {
        drivingType: "",
        drivingLicense: "",
        drivingEndDate: "",
        drivingEndWarning: false
      },
      addForm4: {
        vehicleNos: [],
        driverType: ""
      },
      rules: {
        name: [
          { required: true, message: "请输入驾驶员姓名", trigger: "blur" },
          { validator: checkName, trigger: "blur" }
        ],
        companyId: [
          { required: true, message: "请选择所属企业", trigger: "blur" }
        ],
        workNum: [
          { required: true, message: "请输入工号", trigger: "blur" },
          { validator: checkJobNumber, trigger: "blur" }
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "blur" }],
        idCard: [
          { required: true, message: "请输入身份证号码", trigger: "blur" },
          { validator: checkIdentificationNumber, trigger: "blur" }
        ],
        status: [{ required: true, message: "请选择状态", trigger: "change" }],
        phone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" }
        ],
        starLevel: [{ required: true, message: "请选择星级", trigger: "blur" }],
        cardNo: [
          { validator: checkCardNo, trigger: "blur" }
        ],
        credentialsNo: [
          { required: true, message: "请输入从业资格证号", trigger: "blur" },
          { validator: checkQualificationCertificateNumber, trigger: "blur" }
        ],
        credentialsEndDate: [
          { required: true, message: "请选择到期时间", trigger: "change" }
        ],
        drivingEndDate: [
          {
            validator:mergeValue,
            trigger: "blur"
          }
        ],
        drivingLicense: [
          { required: true, message: "请输入驾驶证号", trigger: "blur" },
          { validator: checkDriverLicenseNumber, trigger: "blur" }
        ],
        drivingType: [
          { required: true, message: "请选择驾驶证类型", trigger: "change" }
        ],

        driverType: [
          { required: true, message: "请选择驾驶类型", trigger: "change" }
        ],
        vehicleNos: [
          {
            required: true,
            type: "array",
            message: "请选择车辆",
            trigger: "change"
          }
        ]
      },
      isPhotoExceed: false,
      valueNameAdd: "",
      valueAdd: null,
      choosedCph: "",
      isLongDate:false,//驾驶证有效期截止是否是长期
    }
  },
  computed: {

  },
  watch: {
    "addForm.photos" (val) {
      if (val.length > 2) {
        this.isPhotoExceed = true;
      } else {
        this.isPhotoExceed = false;
      }
    },
    "addForm.idCard" (val) {
      this.addForm3.drivingLicense = val;
      this.addForm2.credentialsNo = val;
    },
    isLongDate(val){
      if(val) {
        this.addForm3.drivingEndDate = "";
        this.$refs.addForm3.clearValidate("drivingEndDate");
      }
    },
    "addForm3.drivingEndDate" (val) {
      if(val)this.isLongDate = false;
    },
  },
  methods: {    
    // 新增/修改驾驶员
    onSave() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          this.$refs.addForm2.validate(valid => {
            if (valid) {
              this.$refs.addForm3.validate(valid => {
                if (valid) {
                  this.$refs.addForm4.validate(valid => {
                    if (valid) {
                      this.$emit('uploading',true);
                      this.addForm.driverPhoto = [];
                      this.addForm.photos.forEach(item => {
                        if (item.name) {
                          this.addForm.driverPhoto.push(item.url);
                        } else {
                          this.addForm.driverPhoto.push(item);
                        }
                      });
                      let { driverType, vehicleNos } = this.addForm4;
                      const data = {
                        ...this.addForm,
                        ...this.addForm2,
                        ...this.addForm3,
                        driverType: driverType,
                        vehicleNos: vehicleNos.length > 0 ? vehicleNos : null
                      };
                      /**
                       * 兼容驾驶证到期时间为长期
                       */
                      if(this.isLongDate){
                        data.drivingEndDate = '2099-12-31';
                      }
                      if (this.title.indexOf("新增") !== -1) {
                        delete data.id;
                        insertDriver(data).then(res => {
                          if (res.code === 1000) {
                            this.$message.success("新增成功");                            
                            this.$emit('upDateData',1)
                          } else {
                            this.$message.error(res.msg);
                          }
                          this.$emit('uploading',false);
                        });
                      } else {
                        updateDriver(data).then(res => {
                          if (res.code === 1000) {
                            this.$message.success("修改成功");
                            this.$emit('upDateData',2)
                          } else {
                            this.$message.error(res.msg);
                          }
                          this.$emit('uploading',false);
                        });
                      }
                    }
                  });
                }
              });
            }
          });
        }
      });
    },
    onClose(){
      this.$refs.companySelect.clearHandle();
      this.$refs.upload.clearFiles();
      this.isPhotoExceed = false;
      this.$refs.addForm.resetFields();
      this.$refs.addForm2.resetFields();
      this.$refs.addForm3.resetFields();
      this.$refs.addForm4.resetFields();
      this.addForm.driverPhoto = [];
      this.addForm.photos = [];
      this.valueAdd = null;
      this.valueNameAdd = "";
      this.choosedCph = "";
      this.addForm4.driverType = "";
      this.addForm4.vehicleNos = [];
      this.$refs.driverCarSearch.resetSelect();
      //提示状态清空
      this.addForm2.credentialsEndWarning = false;
      this.addForm3.drivingEndWarning = false;
    },
    // 获取车辆数据
    getVehIds (val) {
      this.addForm4.vehicleNos = val;
    },
    getAddGroupId (v) {
      this.addForm.companyId = v;
    },
    // 上传接口
    uploadUrl () {
      return config.apiBaseUrl + "base/driver/uploadDriverPicture";
    },
    // 上传请求头
    getHeader () {
      return {
        Authorization:
          sessionStorage.getItem("token") || localStorage.getItem("token"),
        token: sessionStorage.getItem("token") || localStorage.getItem("token")
      };
    },
    // 移除照片确认
    handleBeforeRemove (file, fileList) {
      this.addForm.photos.forEach((item, index) => {
        if (file.response) {
          if (item === file.response.data) {
            this.addForm.photos.splice(index, 1);
          }
        } else {
          if (item.url === file.url) {
            this.addForm.photos.splice(index, 1);
          }
        }
      });
    },

    // 成功添加照片
    handleAvatarSuccess (res, file) {
      if (res.code === 1000) {
        this.addForm.photos.push(res.data);
      }
    },
    queryInfo () {
      getDriverDetail({ id: this.rowData.id }).then(res => {
        if (res.code === 1000) {
          this.$nextTick(() => {
            this.valueAdd = res.data.companyId;
            this.valueNameAdd = this.rowData.companyName;
          });
          this.addForm.photos = [];
          if (res.data.driverPhoto) {
            res.data.driverPhoto.forEach((item, index) => {
              this.addForm.photos.push({
                name: "pic-" + index,
                url: item
              });
            });
          }
          this.addForm = {
            id: this.rowData.id,
            name: res.data.name,
            companyId: res.data.companyId,
            workNum: res.data.workNum,
            sex: res.data.sex + "",
            idCard: res.data.idCard,
            status: res.data.status + "",
            phone: res.data.phone,
            starLevel: res.data.starLevel + "",
            photos: this.addForm.photos,
            cardNo: res.data.cardNo
          };
          this.addForm2 = {
            credentialsNo: res.data.credentialsNo,
            credentialsEndDate: res.data.credentialsEndDate,
            credentialsEndWarning: res.data.credentialsEndWarning
          };
          /**
           * 兼容驾驶证到期时间为长期的
           */       
          let longTemp = new Date('2099-12-31').getTime();
          let currentTemp = new Date(res.data.drivingEndDate).getTime();
          if(longTemp <= currentTemp)this.isLongDate = true;
          this.addForm3 = {
            drivingType: res.data.drivingType,
            drivingLicense: res.data.drivingLicense,
            drivingEndDate: this.isLongDate ? '' : res.data.drivingEndDate,
            drivingEndWarning: res.data.drivingEndWarning
          };
          this.addForm4 = {
            driverType: res.data.driverType,
            vehicleNos: res.data.vehicleNos || []
          };

          if (res.data.vehicleNos && res.data.vehicleNos.length > 0) {
            this.choosedCph = JSON.stringify(res.data.driverVehicleList);
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  created () { 
    if( this.rowData && this.rowData.id)this.queryInfo();     
  },
  mounted () { },

}
</script>
<style lang="scss" scoped>
.flex{
  display: flex;
  .check{
    margin-left: 8px;
  }
}
.add-driver{
  @include themify() {
  .form-title {
    font-size: 14px;
    color: themed('n7');
    margin-bottom: 10px;
  }
  .form-title2 {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .form-title3 {
    margin: 10px 0 10px;
  }
  .form-title4 {
    color: themed('r4');
  }
  .photo-area {
    padding-bottom: 16px;
  }
}
}


/deep/.expired .el-input__inner {
  color: #ff1212 !important;
}
</style>
