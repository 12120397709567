<template>
  <el-badge :value="value.length" :hidden="!value.length" class="item">
    <el-select
      class="select-width"
      v-model="value"
      multiple
      filterable
      remote
      collapse-tags
      placeholder="请输入关键词"
      :remote-method="remoteMethod"
      :loading="loading"
      @change="handleSelect"
      :multiple-limit="limit"
      :reserve-keyword="false"
    >
      <el-option
        v-for="item in options"
        :key="item.id"
        :label="item.cph"
        :value="item[label]"
      ></el-option>
    </el-select>
  </el-badge>
</template>

<script>
/**
 * 选择车辆组件   模糊搜索 可多选|可与企业联动
 * 需要数据为车架号
 */
import { queryDriverVehicleFuzzy } from '@/api/lib/refreshAlarm.js'

export default {
  name: 'carTree',
  props: {
    label: {
      type: String,
      default: 'vehicleNo'
    },

    /**企业车辆 联动 */
    companyId: {
      type: [Number, String]
    },
    /**驾驶类型 联动 */
    driverType: {
      type: [Number, String],
      default: null
    },
    /* 已选中车牌号 */
    choosedCph: {
      type: String,
      default: ''
    },

  },
  data () {
    return {
      options: [],
      value: [],
      loading: false
    }
  },
  computed: {
    limit: function () {
      return Number(this.driverType) === 0 ? 1 : 50
    }
  },
  methods: {
    remoteMethod (query) {
      if (!this.companyId) {
        this.$message.warning("请先选择企业")
        this.options = []
        return
      }
      if (this.driverType == null || this.driverType == '') {
        this.$message.warning("请先选择驾驶类型")
        this.options = []
        return
      }
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          let data = { cph: query, companyId: this.companyId, "driverType": this.driverType }
          queryDriverVehicleFuzzy(data).then((res) => {
            if (res.code === 1000) {
              this.options = res.data
            }
          })
        }, 200)
      } else {
        this.options = []
      }
    },
    handleSelect () {
      this.$emit('getData', this.value)
    },
    resetSelect () {
      this.value = []
      this.options = []
      this.$emit('getData', this.value)
    }
  },
  created () { },
  mounted () { },
  watch: {
    choosedCph (val, oldVal) {
      if (val) {
        this.options = JSON.parse(val)
        this.options.map(item => this.value.push(item[this.label]))
      }
    }
  }
}
</script>
