var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-driver" },
    [
      _c(
        "el-form",
        {
          ref: "addForm",
          staticClass: "demo-form-inline dialog-form dialog-form-inline",
          attrs: {
            model: _vm.addForm,
            rules: _vm.rules,
            inline: true,
            "label-width": "110px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "驾驶员姓名：", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入姓名" },
                model: {
                  value: _vm.addForm.name,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "所属企业：", prop: "companyId" } },
            [
              _c("companySelect", {
                ref: "companySelect",
                attrs: {
                  clearable: true,
                  companyTree: _vm.companyList,
                  valueName: _vm.valueNameAdd,
                  value: _vm.valueAdd
                },
                on: { getValue: _vm.getAddGroupId }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "工号：", prop: "workNum" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入工号" },
                model: {
                  value: _vm.addForm.workNum,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm,
                      "workNum",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.workNum"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "性别：", prop: "sex" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "" },
                  model: {
                    value: _vm.addForm.sex,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.addForm,
                        "sex",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "addForm.sex"
                  }
                },
                _vm._l(_vm.sexList, function(item) {
                  return _c("el-option", {
                    key: item.dictCode,
                    attrs: { label: item.dictValue, value: item.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "身份证号码：", prop: "idCard" } },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.isIDCardEdit,
                  placeholder: "请输入身份证号码"
                },
                model: {
                  value: _vm.addForm.idCard,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm,
                      "idCard",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.idCard"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "联系电话：", prop: "phone" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入联系电话" },
                model: {
                  value: _vm.addForm.phone,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm,
                      "phone",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.phone"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "星级：", prop: "starLevel" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "" },
                  model: {
                    value: _vm.addForm.starLevel,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.addForm,
                        "starLevel",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "addForm.starLevel"
                  }
                },
                _vm._l(_vm.starLevelList, function(item) {
                  return _c("el-option", {
                    key: item.dictCode,
                    attrs: { label: item.dictValue, value: item.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "卡号：", prop: "cardNo" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入卡号" },
                model: {
                  value: _vm.addForm.cardNo,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm,
                      "cardNo",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.cardNo"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("p", { staticClass: "from-title form-title3" }, [
        _vm._v("驾驶员照片")
      ]),
      _c(
        "div",
        { staticClass: "form-area photo-area" },
        [
          _c(
            "el-upload",
            {
              ref: "upload",
              class: { disUoloadSty: _vm.isPhotoExceed },
              attrs: {
                action: _vm.uploadUrl(),
                headers: _vm.getHeader(),
                accept: "image/png, image/gif, image/jpg, image/jpeg",
                "list-type": "picture-card",
                limit: 3,
                "file-list": _vm.addForm.photos,
                "before-remove": _vm.handleBeforeRemove,
                "on-success": _vm.handleAvatarSuccess
              }
            },
            [_c("i", { staticClass: "el-icon-plus" })]
          )
        ],
        1
      ),
      _vm._m(0),
      _c(
        "el-form",
        {
          ref: "addForm2",
          staticClass: "demo-form-inline dialog-form dialog-form-inline",
          attrs: {
            model: _vm.addForm2,
            rules: _vm.rules,
            inline: true,
            "label-width": "110px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "资格证号：", prop: "credentialsNo" } },
            [
              _c("el-input", {
                attrs: { disabled: true, placeholder: "请输入从业资格证号" },
                model: {
                  value: _vm.addForm2.credentialsNo,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm2,
                      "credentialsNo",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm2.credentialsNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "到期时间：", prop: "credentialsEndDate" } },
            [
              _c("el-date-picker", {
                class:
                  _vm.addForm2.credentialsEndWarning &&
                  _vm.addForm2.credentialsEndWarning
                    ? "expired"
                    : "",
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  type: "date",
                  placeholder: "选择日期"
                },
                model: {
                  value: _vm.addForm2.credentialsEndDate,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm2, "credentialsEndDate", $$v)
                  },
                  expression: "addForm2.credentialsEndDate"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._m(1),
      _c(
        "el-form",
        {
          ref: "addForm3",
          staticClass: "demo-form-inline dialog-form dialog-form-inline",
          attrs: {
            model: _vm.addForm3,
            rules: _vm.rules,
            inline: true,
            "label-width": "110px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "驾驶证号码：", prop: "drivingLicense" } },
            [
              _c("el-input", {
                attrs: { disabled: true, placeholder: "请输入驾驶证号码" },
                model: {
                  value: _vm.addForm3.drivingLicense,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm3,
                      "drivingLicense",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm3.drivingLicense"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "到期时间：", prop: "drivingEndDate" } },
            [
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c("el-date-picker", {
                    class:
                      _vm.addForm3.drivingEndWarning &&
                      _vm.addForm3.drivingEndWarning
                        ? "expired"
                        : "",
                    attrs: {
                      disabled: _vm.isLongDate,
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: _vm.isLongDate ? "长期" : "选择日期"
                    },
                    model: {
                      value: _vm.addForm3.drivingEndDate,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm3, "drivingEndDate", $$v)
                      },
                      expression: "addForm3.drivingEndDate"
                    }
                  }),
                  _c(
                    "el-checkbox",
                    {
                      staticClass: "check",
                      model: {
                        value: _vm.isLongDate,
                        callback: function($$v) {
                          _vm.isLongDate = $$v
                        },
                        expression: "isLongDate"
                      }
                    },
                    [_vm._v("长期")]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "el-form-item",
            { attrs: { label: "驾驶证类型：", prop: "drivingType" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "" },
                  model: {
                    value: _vm.addForm3.drivingType,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.addForm3,
                        "drivingType",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "addForm3.drivingType"
                  }
                },
                _vm._l(_vm.drivingTypeList, function(item) {
                  return _c("el-option", {
                    key: item.dictCode,
                    attrs: { label: item.dictValue, value: item.dictCode }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._m(2),
      _c(
        "el-form",
        {
          ref: "addForm4",
          staticClass: "demo-form-inline dialog-form dialog-form-inline",
          attrs: {
            model: _vm.addForm4,
            rules: _vm.rules,
            inline: true,
            "label-width": "110px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "类型：",
                prop: _vm.addForm4.vehicleNos.length > 0 ? "driverType" : ""
              }
            },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择类型" },
                  model: {
                    value: _vm.addForm4.driverType,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm4, "driverType", $$v)
                    },
                    expression: "addForm4.driverType"
                  }
                },
                _vm._l(_vm.drivingChoiceList, function(item) {
                  return _c("el-option", {
                    key: item.dictCode,
                    attrs: { label: item.dictValue, value: item.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "选择车辆：",
                prop: _vm.addForm4.driverType === "0" ? "vehicleNos" : ""
              }
            },
            [
              _c("driverCarSearch", {
                ref: "driverCarSearch",
                attrs: {
                  driverType: _vm.addForm4.driverType,
                  companyId: _vm.addForm.companyId,
                  choosedCph: _vm.choosedCph
                },
                on: { getData: _vm.getVehIds }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("p", { staticClass: "form-title form-title3 form-title4" }, [
        _vm._v(
          " 备注：固定驾驶某辆车的驾驶员，选择主驾，只绑定一辆车；机动驾驶员，可不设置或者设置多辆驾驶车辆 "
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "form-title form-title3" }, [
      _c("span", [_vm._v("从业资格证信息")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "form-title form-title3" }, [
      _c("span", [_vm._v("驾驶证信息")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "form-title form-title3" }, [
      _c("span", [_vm._v("驾驶车辆")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }